import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  flex-direction: ${(props) => (props.even ? 'row' : 'row-reverse')};
  max-width: 1400px;
  margin-top: 50px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 90%;
  }
  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-top: 30px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  background-color: ${(props) => props.theme.blueLight};
  padding: 0px 0px 50px;
  @media (max-width: ${breakpoints.s}px) {
    padding: 30px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 0px 0px 30px;
  }
`
export const LogoContainer = styled.div`
  width: 450px;
  img {
    border-radius: 25px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    width: 400px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
export const Header = styled.h1`
  color: ${(props) => props.theme.blueDark};
  text-align: center;
  margin: 10px 0px;
  @media (max-width: ${breakpoints.l}px) {
    margin: 20px 0px 10px;
  }
`
export const Description = styled.p`
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.09px;
  max-width: 100%;
  text-align: left;
  color: ${(props) => props.theme.blueDark};
  font-family: 'FilsonProBook';
  margin: 5px 15px;
  @media (max-width: ${breakpoints.s}px) {
    line-height: 20px;
  }
`
export const IconContainer = styled.div`
  min-width: 35px;
  @media (max-width: ${breakpoints.l}px) {
    min-width: 42px;
  }
`
export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.l}px) {
    margin: 10px 0px;
  }
`
export const TextContainer = styled.div`
  width: 600px;
  padding: 0px 80px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 450px;
    padding: 0px 40px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    padding: 0px 0px;
  }
`
export const RowContainer = styled.div`
  max-width: 1400px;
  margin: auto;
`

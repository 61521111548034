import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const GuaranteeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 60px;
  max-width: 90%;
  margin: auto;
  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 16px 0px 40px;
  }
`
export const Heading = styled.h1`
  font-size: 42px;
  line-height: 55px;
  letter-spacing: 0.08px;
  margin: 0px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.15px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
    margin: 30px 0px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 24px;
  }
`
export const Subheader = styled.p`
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.09px;
  margin: 0px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 22px;
    line-height: 27px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
  }
`
export const GuaranteeImageContainer = styled.div`
  width: 230px;
  margin: 0px 70px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 200px;
    margin: 0px 30px;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 60%;
    max-width: 250px;
  }
  @media (max-width: ${breakpoints.m}px) {
  }
`
export const TextBox = styled.div`
  width: 600px;
  text-align: center;
  @media (max-width: ${breakpoints.m}px) {
    width: 90%;
    margin: auto;
  }
`

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { BuyNowLink } from '../Hero/styles'

import { CtaContainer } from '../HomepageProducts/styles'
import { BUY_LINK, CAL_BUY_LINK } from '../../utils/constants'
import {
  Container,
  Row,
  LogoContainer,
  Header,
  Description,
  IconContainer,
  StepsContainer,
  TextContainer,
  RowContainer,
} from './styles'

const HomepageProductDetails = () => {
  const { frameProduct, calendarProduct, plugImg, mailImg, heartImg, syncImg, familyImg, listImg } =
    useStaticQuery(graphql`
      query HomepageProductDetailsQuery {
        frameProduct: file(relativePath: { eq: "homepage/frame-details.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        calendarProduct: file(relativePath: { eq: "calendar/chore-chart-update.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        plugImg: file(relativePath: { eq: "effortlessToUse/plug-blue.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        mailImg: file(relativePath: { eq: "effortlessToUse/mail-blue.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        heartImg: file(relativePath: { eq: "effortlessToUse/heart-blue.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        syncImg: file(relativePath: { eq: "homepage/sync.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        familyImg: file(relativePath: { eq: "homepage/family.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        listImg: file(relativePath: { eq: "calendar/meal-plan.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    `)

  const productDetails = [
    {
      name: 'Skylight Frame',
      ctaText: 'Explore Frame',
      link: BUY_LINK,
      image: frameProduct,
      alt: 'Skylight Frame with smiling girl wearing orange dress with white poka dots',
      images: [
        { icon: plugImg, alt: 'Plug Icon', text: 'Quick 1-minute setup - easy for all ages.' },
        {
          icon: mailImg,
          alt: 'Mail Icon',
          text: 'Email photos or upload through the free Skylight App. No subscription required!',
        },
        {
          icon: heartImg,
          alt: 'Heart Icon',
          text: 'Effortless touchscreen display. Simply tap the heart button to say thank you!',
        },
      ],
    },
    {
      name: 'Skylight Calendar',
      ctaText: 'Explore Calendar',
      link: CAL_BUY_LINK,
      image: calendarProduct,
      alt: 'Skylight Calendar on day view',
      images: [
        {
          icon: syncImg,
          alt: 'Sync Icon',
          text: "Sync and display everyone's calendars in one place.",
        },
        {
          icon: familyImg,
          alt: 'Family Icon',
          text: 'Encourage healthy habits and routines with the interactive chore chart.',
        },
        {
          icon: listImg,
          alt: 'Clipboard and pen Icon',
          text: 'Create to-dos, grocery, and custom lists to help your family stay organized.',
        },
      ],
    },
  ]

  return (
    <Container>
      <RowContainer>
        {productDetails.map((product, i) => (
          <Row key={i} even={i % 2 === 0}>
            <LogoContainer id={i}>
              <GatsbyImage
                image={product.image.childImageSharp.gatsbyImageData}
                alt={product.alt}
              />
            </LogoContainer>
            <TextContainer>
              <Header>{product.name}</Header>
              {product.images.map((detail) => (
                <StepsContainer key={detail.alt}>
                  <IconContainer>
                    <GatsbyImage
                      image={detail.icon.childImageSharp.gatsbyImageData}
                      alt={detail.alt}
                    />
                  </IconContainer>
                  <Description>{detail.text}</Description>
                </StepsContainer>
              ))}
              <CtaContainer>
                <BuyNowLink to={product.link}>{product.ctaText}</BuyNowLink>
              </CtaContainer>
            </TextContainer>
          </Row>
        ))}
      </RowContainer>
    </Container>
  )
}

export default HomepageProductDetails
